// Galician
export default{
    form: {
        startSurveyButton: "Comezamos",
        nextButton: "Seguinte",
        submitAnswerButton: "Enviar resposta",
        continueButton: "Continuar",
        finishButton: "Rematar",
        headerPercentageCompletion: 'completado',
        headerQuestionsCompleted: 'preguntas',
        headerDefaultErrorMessage: 'Ups! Algo fallou.',
        brandingPoweredBy: 'Desenvolvido por',
        brandingPromoWhySurvey: 'Por que facer unha enquisa se podes usar <b>{product}</b>?',
        brandingPromoGetStarted: 'Comeza hoxe!',
        submissionPending: "Enviando o teu voiceform. Non peches a xanela do navegador.",
        submissionCanCloseWindow: "O teu voiceform foi enviado con éxito. Xa podes pechar a xanela 👍",
        mediaAttachmentOverlayCloseButton: "Pechar",
        expiredFormHeader: "Grazas por visitarnos!",
        expiredFormMessage: "Parece que esta enquisa caducou. Contacta co creador do voiceform para avisalo.",
        notPublishedFormHeader: "O voiceform non está publicado!",
        notPublishedFormMessage: "Parece que esta enquisa non está publicada. Contacta co creador do voiceform para avisalo.",
        notPublishedFormRetryButton: "Tentar de novo",
        errorFormHeader: "Ups!",
        errorFormMessage: "Algo fallou. Inténtao de novo máis tarde.",
        errorFormRetryButton: "Tentar de novo",
        emptyFormHeader: "Grazas por visitarnos!",
        emptyFormMessage: "Ups! Parece que este formulario está baleiro, sen preguntas ou campos para encher. Pedímosche desculpas polas molestias. Contacta co creador do formulario para informalo do problema. Grazas pola túa comprensión e colaboración!",

        restoreFormHeader: "Parece que tes un formulario sen rematar!",
        restoreFormMessage: "Queres continuar onde o deixaches?",
        restoreFormButton: "Continuar formulario",
        restoreFormButtonNew: "Comezar de novo",

        audioPermissionHeader: "Queres responder coa túa voz?",
        audioPermissionMessage: "Falar fará que responder sexa máis rápido e sinxelo.",
        audioPermissionAcceptButton: "Si, quero",
        audioPermissionDenyButton: "Non, grazas",
        audioPermissionErrorHeader: "O micrófono non está dispoñible",
        audioPermissionErrorMessage: "Comproba os permisos do navegador para activar o acceso ao micrófono.",
        audioPermissionErrorButton: "De acordo",
        audioRecorderFailedUpload: "Non se puido cargar a gravación",
        silenceDetectorError: "Non te escoitamos. Comproba as preferencias do micrófono.",
        audioRecorderButtonRecord: "Gravar",
        audioRecorderButtonRecordMore: "Gravar máis",
        voiceResponseSelectionMessage: "Elixe como queres responder...",
        voiceResponseSelectionOrDivider: "ou",
        voiceResponseTextInputPlaceholder: "Escribe a resposta",
        voiceResponseTextInputCharacterCounterMinMessage: "Introduce polo menos {min} caracteres",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} caracteres (mínimo {min} caracteres)",
        voiceResponseTextInputCharacterValidationMessage: "{count} caracteres (mínimo {min} caracteres)",

        // checkbox
        checkboxValidationTooFew: "Debes seleccionar polo menos {min} opción(s)",
        checkboxValidationTooMany: "Debes seleccionar como máximo {max} opción(s)",
        checkboxNoneOfTheAboveOption: "Ningún dos anteriores",
        checkboxInvalidAnswer: "Resposta non válida.",

        // datepicker
        datePickerPlaceholder: "Selecciona unha data",

        // dropdown
        // email
        emailLabel: "Correo electrónico",
        emailInvalid: "Correo electrónico non válido.",
        // file-upload
        fileUploadPluginNameCamera: "Cámara",
        fileUploadPluginNameCameraVideo: "Gravar vídeo",
        fileUploadPluginScreenCast: "Gravar pantalla",
        fileUploadVideoImportFilesDefault: 'Grava ou sube un vídeo a través de:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Selecciona unha opción para gravar vídeo:',
        fileUploadVideoImportFilesNoCamera: 'Preme un botón para gravar un vídeo de pantalla',
        fileUploadVideoImportFilesNoScreenCast: 'Preme un botón para gravar un vídeo',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Non hai opcións dispoñibles para gravar un vídeo',

        // matrix
        matrixValidationMessage: "A resposta é válida.",
        matrixRow: "Fila",
        matrixColumn: "Columna",
        matrixRowRequired: "A fila {rowTitle} é obrigatoria.",
        matrixRadioGroup: "Grupo de radio",
        matrixCheckboxGroup: "Grupo de caixas de selección",
        matrixGroupRequired: "Para a fila {rowTitle}. {type} {groupTitle} é obrigatorio.",
        matrixColumnRequired: "Para a fila {rowTitle}. A columna {columnTitle} é obrigatoria.",
        matrixColumnInvalid: "Para a fila {rowTitle}. A columna {columnTitle} non é válida.",
        matrixRequired: "Obrigatorio.",
        matrixNumericMustBeNumber: "Debe ser un número.",
        matrixNumericMustBeGreaterThenMin: "Debe ser maior que {min}.",
        matrixNumericMustBeLessThenMax: "Debe ser menor que {max}.",
        matrixNumericMustBeInteger: "Debe ser un número enteiro.",
        matrixNumericInvalidNumber: "Número non válido.",

        // name
        nameLabel: "Nome",
        nameInvalid: "Indica o nome e o apelido.",

        // nps
        npsNotLikely: "Nada probable",
        npsExtremelyLikely: "Moi probable",

        // numeric input
        numericInputRequired: "Obrigatorio.",
        numericInputMustBeNumber: "Debe ser un número.",
        numericInputMustBeGreaterThenMin: "Debe ser maior que {min}.",
        numericInputMustBeLessThenMax: "Debe ser menor que {max}.",
        numericInputMustBeInteger: "Debe ser un número enteiro.",
        numericInputInvalidNumber: "Número non válido.",
        numericInputPlaceholder: "Introduce un número",

        // phone
        phoneInvalid: "O número de teléfono non é válido.",
        phoneCountrySelectorLabel: 'Código de país',
        phoneCountrySelectorError: 'Selecciona un país',
        phoneNumberLabel: 'Número de teléfono',
        phoneExample: 'Exemplo:',

        // boolean
        booleanYesLabel: "Si",
        booleanNoLabel: "Non",

        //questionStep
        questionStepAudioQuestionLabel: "Pregunta de audio",

        // errors
        invalidPhoneNumber: "{phone} é un número de teléfono non válido.",
        invalidEmail: "{email} é un correo electrónico non válido.",
        questionIsRequired: "A pregunta é obrigatoria.",
        answerIsNotValid: "A resposta non é válida.",
        unfinishedProbingDialogError: "Completa o diálogo.",
        cannotResumePartialResponse: "Non se pode continuar coa resposta parcial.",
        failedToSubmitForm: "Non se puido enviar o formulario. Verifica a conexión a Internet e inténteo de novo.",

        // language picker
        searchLanguage: "Buscar idioma",
    }
}
